import styled from 'styled-components';
import cn from 'classnames';
import Color from 'color';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getUTCDate } from '../../library/date.lib';

export default function ExhibitionUpcoming() {
  const history = useHistory();
  const location = useLocation();

  const [exhibtions, setExhibitions] = useState<any[]>([]);

  const getExhibition = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition/up`)
      .then(async (response) => {
        setExhibitions(response.data.data.upcoming);
      });
  };

  useEffect(() => {
    getExhibition();
  }, []);

  return (
    <Box>
      <div className={cn('btn_wrap')}>
        <span
          className={cn('btn_wrap-btn')}
          onClick={() => history.push('/exhibition/onview')}
          style={{
            backgroundColor: location.pathname.includes('/onview')
              ? '#000'
              : '',
            color: location.pathname.includes('/onview') ? '#fff' : '',
          }}
        >
          On View
        </span>

        <span
          className={cn('btn_wrap-btn')}
          onClick={() => history.push('/exhibition-upcoming')}
          style={{
            backgroundColor: location.pathname.includes('/exhibition-upcoming')
              ? '#000'
              : '',
            color: location.pathname.includes('/exhibition-upcoming')
              ? '#fff'
              : '',
          }}
        >
          Upcoming
        </span>

        <span
          className={cn('btn_wrap-btn')}
          onClick={() => history.push('/exhibition-past')}
          style={{
            backgroundColor: location.pathname.includes('/exhibition-past')
              ? '#000'
              : '',
            color: location.pathname.includes('/exhibition-past') ? '#fff' : '',
          }}
        >
          Past
        </span>
      </div>

      {exhibtions.length ? (
        <div className={cn('list')}>
          {exhibtions.map((exhibition, index) => {
            return (
              <div key={index} className={cn('list-item')} style={{ backgroundImage: exhibition.thumbnail ? `url(${exhibition.thumbnail})` : '' }}>
                <div className={cn('list-item-wrap')}>
                  <span className={cn('list-item-wrap-title')}>
                    {exhibition.title}
                  </span>

                  <span className={cn('list-item-wrap-date')}>
                    {`${getUTCDate(String(exhibition.date).split('|')[0])
                      .format('MMM DD YYYY')
                      .toString()} - ${getUTCDate(
                      String(exhibition.date).split('|')[1]
                    )
                      .format('MMM DD YYYY')
                      .toString()}`}
                  </span>

                  <span className={cn('list-item-wrap-location')}>
                    {exhibition.location}
                  </span>

                  <span className={cn('list-item-wrap-text')}>
                    Preparing for an exhibition
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <span className={cn('empty')}>Preparing for an exhibition</span>
      )}
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .btn_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 30px 0;

    &-btn {
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      margin-right: 20px;
      padding: 10px 20px;
      border-radius: 4px;
      border: 1px solid #000;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .empty {
    width: fit-content;
    height: fit-content;
    margin: 30px auto 50px auto;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    color: #fff;
    background-color: #ced4da;
    padding: 10px 20px;
    border-radius: 4px;
    letter-spacing: 1px;
    user-select: none;
  }

  .list {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;

    &-item {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-bottom: 20px;
      border-radius: 4px;
      background-size: cover;
      background-position: center;

      &:last-child {
        margin-bottom: 0;
      }

      &-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: ${Color('#ced4da').alpha(0.7).hsl().toString()};

        &-title {
          width: fit-content;
          height: fit-content;
          font-family: 'Noto Serif';
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 20px;
          color: #000;
        }

        &-date {
          color: #495057;
          letter-spacing: 1px;
          margin-bottom: 5px;
        }

        &-location {
          color: #495057;
          letter-spacing: 1px;
          margin-bottom: 30px;
        }

        &-text {
          font-family: 'Noto Serif';
          font-size: 15px;
          font-style: italic;
          letter-spacing: 1px;
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
`;
