import styled from 'styled-components';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import ICON_1_AFTER from '../../static/images/artist_icon_1_after.svg';
import ICON_1_BEFORE from '../../static/images/artist_icon_1_before.svg';
import ICON_2_AFTER from '../../static/images/artist_icon_2_after.svg';
import ICON_2_BEFORE from '../../static/images/artist_icon_2_before.svg';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function Artist() {
  const history = useHistory();

  const [isType, setIsType] = useState(true);
  const [selectType, setSelectType] = useState(0);

  const [artists, setArtists] = useState<any[]>([]);
  const [selectArtists, setSelectArtists] = useState<any[]>([]);

  const handleABC = (index: number) => {
    switch (index) {
      case 1:
        return 'A';

      case 2:
        return 'B';

      case 3:
        return 'C';

      case 4:
        return 'D';

      case 5:
        return 'E';

      case 6:
        return 'F';

      case 7:
        return 'G';

      case 8:
        return 'H';

      case 9:
        return 'I';

      case 10:
        return 'J';

      case 11:
        return 'K';

      case 12:
        return 'L';

      case 13:
        return 'M';

      case 14:
        return 'N';

      case 15:
        return 'O';

      case 16:
        return 'P';

      case 17:
        return 'Q';

      case 18:
        return 'R';

      case 19:
        return 'S';

      case 20:
        return 'T';

      case 21:
        return 'U';

      case 22:
        return 'V';

      case 23:
        return 'W';

      case 24:
        return 'X';

      case 25:
        return 'Y';

      case 26:
        return 'Z';

      default:
        break;
    }
  };

  const handleArtist = (id: any) => {
    history.push(`/artist/${id}`);
  };

  const getArtist = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist/abc`)
      .then((response) => {
        setArtists(response.data.data);
        setSelectArtists(response.data.data[0]);
      });
  };

  useEffect(() => {
    if (!artists.length) return;

    setSelectArtists(artists[selectType]);
  }, [artists, selectType]);

  useEffect(() => {
    getArtist();
  }, []);

  return (
    <Box>
      <span className={cn('title')}>Artist</span>

      <div className={cn('wrap')}>
        <div className={cn('wrap-abc_wrap')}>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(1)}
          >
            A
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(2)}
          >
            B
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(3)}
          >
            C
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(4)}
          >
            D
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(5)}
          >
            E
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(6)}
          >
            F
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(7)}
          >
            G
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(8)}
          >
            H
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(9)}
          >
            I
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(10)}
          >
            J
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(11)}
          >
            K
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(12)}
          >
            L
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(13)}
          >
            M
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(14)}
          >
            N
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(15)}
          >
            O
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(16)}
          >
            P
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(17)}
          >
            Q
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(18)}
          >
            R
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(19)}
          >
            S
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(20)}
          >
            T
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(21)}
          >
            U
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(22)}
          >
            V
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(23)}
          >
            W
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(24)}
          >
            X
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(25)}
          >
            Y
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(26)}
          >
            Z
          </span>
          <span
            className={cn('wrap-abc_wrap-text')}
            onClick={() => setSelectType(0)}
          >
            ALL
          </span>
        </div>

        <img
          className={cn('wrap-icon')}
          src={isType ? ICON_2_AFTER : ICON_2_BEFORE}
          alt='icon'
          draggable={false}
          style={{ marginRight: '20px' }}
          onClick={() => setIsType(true)}
        />

        <img
          className={cn('wrap-icon')}
          src={!isType ? ICON_1_AFTER : ICON_1_BEFORE}
          alt='icon'
          draggable={false}
          onClick={() => setIsType(false)}
        />
      </div>

      {isType ? (
        <div className={cn('list_1')}>
          {selectArtists.map((artist, index) => {
            return (
              <div key={index} className={cn('list_1-item')}>
                {artist.thumbnail || artist.artworks.length ? (
                  <img
                    className={cn('list_1-item-img')}
                    src={
                      artist.thumbnail
                        ? artist.thumbnail
                        : artist.artworks[0].thumbnail
                    }
                    alt='artwork_img'
                    draggable={false}
                    onClick={() => handleArtist(artist.id)}
                  />
                ) : (
                  <div
                    className={cn('list_1-item-notfound')}
                    onClick={() => handleArtist(artist.id)}
                  />
                )}

                <span
                  className={cn('list_1-item-name')}
                  onClick={() => handleArtist(artist.id)}
                >
                  {artist.name}
                </span>

                <span
                  className={cn('list_1-item-text')}
                  onClick={() => handleArtist(artist.id)}
                >
                  {artist.country}, b. {artist.year_of_birth}
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={cn('list_2')}>
          {artists.map((arr: any[], i) => {
            if (i === 0) return <></>;

            return (
              <div key={i} className={cn('list_2-wrap')}>
                <span className={cn('list_2-wrap-title')}>{handleABC(i)}</span>

                <div className={cn('list_2-wrap-artists')}>
                  {arr.map((artist: any, index_2: number) => {
                    return (
                      <div
                        key={index_2}
                        className={cn('list_2-wrap-artists-item')}
                        onClick={() => handleArtist(artist.id)}
                      >
                        <span className={cn('list_2-wrap-artists-item-name')}>
                          {artist.name}
                        </span>

                        <span className={cn('list_2-wrap-artists-item-text')}>
                          {artist.country}, b. {artist.year_of_birth}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;

  .title {
    width: fit-content;
    height: fit-content;
    font-family: 'Noto Serif';
    font-size: 20px;
    font-weight: 400;
    margin: 30px 0;
  }

  .wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 50px;

    &-abc_wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;

      &-text {
        width: calc(100% / 6);
        font-size: 15px;
        font-weight: 400;
        cursor: pointer;
        user-select: none;
        padding: 10px 20px;
        text-align: center;
      }
    }

    &-icon {
      width: 25px;
      height: auto;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .list_1 {
    width: 100%;
    height: fit-content;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    row-gap: 30px;
    column-gap: 20px;

    &-item {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: center;
        margin-bottom: 20px;
        cursor: pointer;
      }

      &-notfound {
        width: 100%;
        height: 200px;
        background-color: #f1f3f5;
        margin-bottom: 20px;
        cursor: pointer;
      }

      &-name {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
        user-select: none;
      }

      &-text {
        color: #898989;
        user-select: none;
      }
    }
  }

  .list_2 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 30px 0;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: 0;
      }

      &-title {
        font-family: 'Noto Serif';
        font-weight: 600;
        font-size: 24px;
        margin-right: 30px;
        user-select: none;
      }

      &-artists {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &-item {
          width: fit-content;
          height: fit-content;
          display: flex;
          flex-direction: column;
          margin-right: 20px;
          margin-bottom: 20px;
          cursor: pointer;

          &-name {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 5px;
            user-select: none;
          }

          &-text {
            color: #898989;
            user-select: none;
          }
        }
      }
    }
  }
`;
